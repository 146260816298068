import { Box, Link, useMediaQuery, useTheme } from '@material-ui/core';

export const MenuLinks = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Box
      display='flex'
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyItems='space-between'
      alignItems='center'
      fontWeight={600}
    >
      <Box marginLeft={isMobile ? 0 : 4} marginTop={isMobile ? 2 : 0}>
        <Link href='https://rebasebase.com'>1stBase</Link>
      </Box>
      <Box marginLeft={isMobile ? 0 : 4} marginTop={isMobile ? 2 : 0}>
        <Link href='https://wrap.rebasebase.com'>Wrapper</Link>
      </Box>
      <Box marginLeft={isMobile ? 0 : 4} marginTop={isMobile ? 2 : 0}>
        <Link href='https://volcano.rebasebase.com'>Volcano</Link>
      </Box>
    </Box>
  );
};
